import React from "react";
import "./ListItem.css";

const ListItem = ({ word }) => {
  return (
    <>
      <div className="word">
        <div className="word__part kanji">{word.japanese_kanji}</div>
        <div className="word__part latin">{word.japanese_latin}</div>
        <div className="word__part termtype">{word.type}</div>
        <div className="word__part english">{word.english}</div>

        <div className="word__part definition">{word.definition}</div>
      </div>
    </>
  );
};

export default ListItem;
