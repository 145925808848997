import React, { useState, useEffect, useCallback } from "react";
import ListItem from "./components/ListItem";
import gloss from "./gloss.json";
import "./App.css";

function App() {
  const [isFocused, setFocused] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const [activeTypes, setActiveTypes] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [words, setWords] = useState([]);

  const [isHeaderVisible, setHeaderVisible] = useState(true);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  useEffect(() => {
    if (isFocused) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [isFocused]);

  const allTypesSet = new Set(gloss.map((item) => item.type));
  const allTypes = Array.from(allTypesSet).filter((type) => type !== "");
  if (allTypesSet.has("")) {
    allTypes.push("general");
  }

  const getFilteredWords = useCallback(() => {
    const normalizedTarget = searchInput
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

    return gloss
      .filter((w) => {
        const normalizedKanji = w.japanese_kanji
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        const normalizedLatin = w.japanese_latin
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        const normalizedEnglish = w.english
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        return (
          (normalizedKanji?.includes(normalizedTarget) ||
            normalizedLatin?.includes(normalizedTarget) ||
            normalizedEnglish?.includes(normalizedTarget)) &&
          (activeTypes.length === 0 ||
            activeTypes.includes(w.type) ||
            (w.type === "" && activeTypes.includes("general")))
        );
      })
      .sort((a, b) => {
        const aValue = a.japanese_latin?.toLowerCase();
        const bValue = b.japanese_latin?.toLowerCase();
        return aValue.localeCompare(bValue);
      });
  }, [searchInput, activeTypes]);

  useEffect(() => {
    setWords(getFilteredWords());
  }, [getFilteredWords]);

  const handleTypeToggle = (type) => {
    if (activeTypes.includes(type)) {
      setActiveTypes(activeTypes.filter((activeType) => activeType !== type));
    } else {
      setActiveTypes([...activeTypes, type]);
    }
  };

  const wordList = words.map((w) => <ListItem key={w.id} word={w} />);

  useEffect(() => {
    let prevScrollPos = window.scrollY;

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrollingUp = prevScrollPos > currentScrollPos;

      // setHeaderVisible(isScrollingUp || currentScrollPos === 0);
      setHeaderVisible(isScrollingUp || currentScrollPos < 300);

      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        style={{
          transform: isHeaderVisible ? "translateY(0)" : "translateY(-100%)",
          transition: "transform 0.3s ease-in-out",
        }}
      >
        <input
          placeholder="search for a kendo term"
          className={`searchbox ${isExpanded ? "expanded" : ""}`}
          type="text"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <div className="button-row">
          {allTypes.map((type) => (
            <button
              key={type}
              className={`type-button ${
                activeTypes.includes(type) ? "active" : ""
              }`}
              onClick={() => handleTypeToggle(type)}
            >
              {type === "" ? "General" : type}
            </button>
          ))}
        </div>
      </header>
      <main>{wordList}</main>
      <footer>Developed by Owen G</footer>
    </>
  );
}

export default App;
